<template>
  <el-main>
    <div class="main">
      <h2 class="title">我的课程</h2>
      <el-row :gutter="50" class="course-box">
        <el-col
          v-for="(item, i) in licenceData"
          :key="item.label"
          :span="12"
          v-bind:class="item.classType ? 'course-list active' : 'course-list'"
          @click.native="changeClass(i)"
        >
          <dl>
            <dt>{{ item.parent_name }}</dt>
            <dd>课程名称：{{ item.data[0].course_name }}</dd>
            <dd v-for="item3 in item.data[0].term" :key="item3.index">
              <span v-if="term == item3.term">
                学 时：{{ item3.show_period }}学时
              </span>
            </dd>
            <el-row class="course-itme">
              <el-col
                v-for="(item3, i) in item.data[0].term"
                :key="item3.index"
                :span="6"
                @click.native="getterm(item3.term, item3.is_pay, i)"
              >
                <a v-bind:class="item3.classType ? 'active' : ''"
                  >第{{ item3.term }}期</a
                >
              </el-col>
            </el-row>
          </dl>
          <div class="course-active">
            <span class="icon-checkmark2"></span>
          </div>
        </el-col>
        <!-- <el-col :span="12" class="course-list">
          <dl>
            <dt>继续教育</dt>
            <dd>课程名称：普通机动车（小型汽车）驾驶员理论培训课程</dd>
            <dd>学 时：000分钟/999分钟</dd>
            <dd>有 效 期：2020-03-01至2023-03-01</dd>
          </dl>
          <div class="course-active">
            <span class="icon-checkmark2"></span>
          </div>
        </el-col>
        <el-col :span="12" class="course-list">
          <dl>
            <dt>从业资格</dt>
            <dd>课程名称：普通机动车（小型汽车）驾驶员理论培训课程</dd>
            <dd>学 时：000分钟/999分钟</dd>
            <dd>有 效 期：2020-03-01至2023-03-01</dd>
          </dl>
          <div class="course-active">
            <span class="icon-checkmark2"></span>
          </div>
        </el-col> -->
      </el-row>
      <div class="course-btn"><a @click="skipcourse">确 定</a></div>
    </div>
  </el-main>
</template>
<script>
import { getSubject } from '../api/courses'
import { changelicence, userlicence } from '../api/user'
import {
  getIsPay,
  getLicenceName,
  getParentId,
  getResult,
  getTerm,
  getTermi,
  removeResult,
  setIsPay,
  setLicenceName,
  setParentId,
  setResult,
  setSubjectId,
  setTerm,
  setTermi
} from '../utils/auth'

export default {
  components: {},
  inject: ['reload'],
  data() {
    return {
      licence_id: 0,
      changeLicenceid: 0,
      changeLicencetitle: '',
      avatar: '',
      real_name: '',
      licence_title: '',
      jiaxiao_name: '',
      licenceData: [],
      licence_name: '',
      termdata: [],
      is: 0,
      term: 0
    }
  },
  created() {
    this.term = getTerm()
    this.licence_name = getLicenceName()
    this.getInfo()
    setTermi(getTerm() - 1)
  },
  methods: {
    //用户信息
    getInfo() {
      if (getResult() === undefined) {
        this.$alert('登录过期，请登录后再访问', '登录提示', {
          confirmButtonText: '重新登录',
          callback: action => {
            removeResult()
            location.href = '#/login'
          }
        })
      } else {
        this.avatar = JSON.parse(getResult()).avatar
        this.real_name = JSON.parse(getResult()).real_name
        this.licence_title = JSON.parse(getResult()).licence_title
        this.changeLicencetitle = JSON.parse(getResult()).licence_title
        this.licence_id = JSON.parse(getResult()).licence_id
        this.changeLicenceid = JSON.parse(getResult()).licence_id
        this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
        this.getLicence()
      }
      // this.avatar = JSON.parse(getResult()).avatar
      // this.real_name = JSON.parse(getResult()).real_name
      // this.licence_title = JSON.parse(getResult()).licence_title
      // this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
    },
    //获取用户车型
    getLicence() {
      userlicence().then(res => {
        if (res.code === 0) {
          res.result = res.result.filter(
            item => item.parent_name !== '公路建设安全'
          )
          this.licenceData = res.result
          for (let s = 0; s < this.licenceData.length; s++) {
            for (let k = 0; k < this.licenceData[s].data.length; k++) {
              // if (this.licence_id != this.licenceData[s].data[k].licence_id) {
              //   this.licenceData[s].data.splice(k, 1)
              // }
              for (
                let i = 0;
                i < this.licenceData[s].data[0].term.length;
                i++
              ) {
                if (getTermi() != undefined) {
                  this.licenceData[s].data[0].term[getTermi()].classType = true
                } else {
                  this.licenceData[s].data[0].term[i].classType = false
                  if (i === 0) {
                    this.licenceData[s].data[0].term[0].classType = true
                  } else {
                    this.licenceData[s].data[0].term[i].classType = false
                  }
                }
              }
            }
            if (getParentId() != undefined) {
              if (getParentId() == this.licenceData[s].parent_id) {
                this.licenceData[s].classType = true
              } else {
                this.licenceData[s].classType = false
              }
            } else {
              if (s === 0) {
                this.licenceData[0].classType = true
              } else {
                this.licenceData[s].classType = false
              }
            }
          }
        }
        for (let s = 0; s < this.licenceData.length; s++) {
          // licence_id是否属于当前类型 （驾驶证，继续教育）
          this.licenceData[s].isSelect = false
          for (let k = 0; k < this.licenceData[s].data.length; k++) {
            if (this.licence_id == this.licenceData[s].data[k].licence_id) {
              this.licenceData[s].isSelect = true
              getSubject({
                licence_id: this.licenceData[s].data[k].licence_id
              }).then(res => {
                if (res.code === 0) {
                  this.$set(
                    this.licenceData[s].data[0],
                    'course_name',
                    res.result.course_name
                  )
                }
              })
              this.licenceData[s].data.splice(0, 0, this.licenceData[s].data[k])
              this.licenceData[s].data.splice(k + 1, 1)
            }
            for (let i = 0; i < this.licenceData[s].data[0].term.length; i++) {
              if (getTermi() != undefined) {
                this.licenceData[s].data[0].term[getTermi()].classType = true
              } else {
                if (i === 0) {
                  this.licenceData[s].data[0].term[0].classType = true
                } else {
                  this.licenceData[s].data[0].term[i].classType = false
                }
              }
            }
          }
          // 如果licence_id不属于当前类型，则取第一个为默认值
          if (!this.licenceData[s].isSelect) {
            getSubject({
              licence_id: this.licenceData[s].data[0].licence_id
            }).then(res => {
              if (res.code === 0) {
                this.$set(
                  this.licenceData[s].data[0],
                  'course_name',
                  res.result.course_name
                )
              }
            })
          }
        }
      })
    },
    //获取期数
    getterm(term, is_pay, i) {
      setIsPay(is_pay)
      setTermi(i)
      setTerm(term)
      for (let s = 0; s < this.licenceData[this.is].data[0].term.length; s++) {
        this.licenceData[this.is].data[0].term[s].classType = false
      }
      this.$set(
        this.licenceData[this.is].data[0].term[i],
        'classType',
        this.licenceData[this.is].data[0].term[i].classType === false
      )
      this.$forceUpdate()
    },
    //修改选中状态
    changeClass(i) {
      this.is = i
      for (let s = 0; s < this.licenceData.length; s++) {
        this.licenceData[s].classType = false
      }
      this.$set(
        this.licenceData[i],
        'classType',
        this.licenceData[i].classType === false
      )
      this.$forceUpdate()
      this.changeLicenceid = this.licenceData[i].data[0].licence_id
      this.changeLicencetitle = this.licenceData[i].data[0].licence_title
      setIsPay(this.licenceData[i].data[0].term[0].is_pay)
      let arr = JSON.parse(getResult())
      arr.licence_id = this.licenceData[i].data[0].licence_id
      getSubject({ licence_id: arr.licence_id }).then(res => {
        if (res.code === 0) {
          if (res.result.subject_list.length > 0) {
            setSubjectId(res.result.subject_list[0].subject_id)
          }
        }
      })
      removeResult()
      setResult(arr)
      setParentId(this.licenceData[i].parent_id)
      setLicenceName(this.licenceData[i].data[0].licence_name)
      this.licence_name = this.licenceData[i].data[0].licence_name

      let params = {
        licence_id: this.changeLicenceid,
        term: getTerm(),
        study_no: JSON.parse(getResult()).study_no
      }
      if (this.changeLicencetitle != null) {
        params['licence_title'] = this.changeLicencetitle
      } else {
        params['licence_title'] = ''
      }
      changelicence(params).then(res => {
        if (res.code === 0) {
          removeResult()
          setResult(res.result)
          setIsPay(res.result.is_pay)
          getSubject({ licence_id: res.result.licence_id }).then(res => {
            if (res.code === 0) {
              if (res.result.subject_list.length > 0) {
                setSubjectId(res.result.subject_list[0].subject_id)
                //获取用户车型
                userlicence().then(res => {
                  if (res.code === 0) {
                    //setLicenceName(res.result[0].data[0].licence_name)
                    //setTerm(res.result[0].data[0].term[0].term)
                    for (let i = 0; i < res.result.length; i++) {
                      for (let s = 0; s < res.result[i].data.length; s++) {
                        if (
                          JSON.parse(getResult()).licence_id ===
                          res.result[i].data[s].licence_id
                        ) {
                          setParentId(res.result[i].parent_id)
                          setLicenceName(res.result[i].data[s].licence_name)
                        }
                      }
                    }
                    this.reload()
                  }
                })
              }
            }
          })
        }
      })
    },
    //页面跳转
    skipcourse() {
      // let params = {
      //   licence_id: this.changeLicenceid,
      //   licence_title: this.changeLicencetitle,
      //   term: getTerm(),
      //   study_no: JSON.parse(getResult()).study_no
      // }
      // changelicence(params).then(res => {
      //   if (res.code === 0) {
      //if (JSON.parse(getResult()).is_pay >= 2) {
      if (getIsPay() >= 2) {
        this.$router.push({ path: '/catalogs' })
      } else {
        this.$router.push({
          path: '/catalogs/shikan?licence_id=' + this.changeLicenceid
        })
      }
      //   }
      // })
    }
  }
}
</script>
